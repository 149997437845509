<template>

  <CRow>
    <c-button color="secondary" size="lg"><a :href="docValue" target="_blank" > View </a></c-button>

    <iframe
        className="doc"
        :src="docValue"
        style="border: none"
    />

  </CRow>

</template>


<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import {profileVerifications} from "/src/store/url";

Vue.use(VueForm);

export default {
  name: "RegDoc",
  mixins: [Mixin],
  data() {
    return {
      self: this,
      module: profileVerifications,
      docType: "office",
      url: "",
      docValue: "",
    };
  },
  created() {

    let self = this;
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    store.commit("showLoader", false); // Loader Off
    axios.get(this.viewUrlApi(this.module, id)).then((response) => {
      let responseData = response.data.data;
      if (responseData) {
        self.url = responseData.regDoc; //&efh=false&a=v
        self.docValue = "https://docs.google.com/gview?url=" + self.url + "&embedded=true";
      }
    });
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    axios.get(this.viewUrlApi(this.module, id)).then((response) => {
      if (response.data.status === 200) {
        const responseData = response.data.data;
        self.url = responseData.regDoc; //&efh=false&a=v
        self.docValue =
            "https://docs.google.com/gview?url=" + self.url + "&embedded=true";
      }
    });
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
  },
};
</script>
<style>
iframe {
  width: 100%;
  height: 500px;
}

.ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-Wrql6b {
  background: rgba(147, 147, 147, 0.7);
}
</style>
